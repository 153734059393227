<template>
  <!-- 权限管理-角色管理 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <template slot="extra">
        <a-button type="primary" @click="handleBase">
          保存
        </a-button>
      </template>
    </a-page-header>

    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">设置角色权限</div>
        </div>
      </div>

      <access-control-checked
        :options="options"
        :selected="selected"
        @change="onAccessControlChecked"
      ></access-control-checked>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Debounce } from '@/utils/public'
import accessControlChecked from '@/components/access-control-checked'
let data = null
let menus = []
let unCheckedNum = 0
export default {
  data() {
    return {
      title: '',
      options: {
        data: [],
        checkAll: false,
        indeterminateAll: false
      },
      selected: []
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    accessControlChecked
  },
  created() {
    const routeData = this.$route.query
    this.title = routeData.name
    data = {
      role_id: routeData.role_id,
      role_level_id: routeData.role_level_id
    }
    this.getAllRoleMenuControls()
  },
  methods: {
    async getAllRoleMenuControls() {
      // 获取所有菜单列表
      menus = await this.$axios.getAllRoleMenuControls()
      this.getRoleLevelMenu()
    },
    getRoleLevelMenu() {
      // 获取对应角色的菜单列表
      this.$axios.getRoleLevelMenu(data).then((res) => {
        const value = res.data.data
        this.selected = value
        if (value.length) {
          this.checkMenu(menus, value)
        } else {
          this.options.data = menus
        }
      })
    },
    checkMenu(arr1, arr2) {
      // 匹配是否已选中
      arr1.forEach((e) => {
        const item = arr2.find((f) => f.id === e.id)
        if (item !== undefined) {
          e.checked = true
          if (e.buttons) {
            this.checkButton(e.buttons, item.buttons)
          }
        } else {
          unCheckedNum++
        }
        if (e.children) {
          this.checkMenu(e.children, arr2)
        }
      })
      this.setData(arr1)
    },
    checkButton(arr1, arr2) {
      arr1.forEach((e) => {
        const item = arr2.find((f) => f.value === e.value)
        if (item !== undefined) {
          e.checked = true
        } else {
          unCheckedNum++
        }
      })
    },
    setData: Debounce(function(arr) {
      const data = {
        data: arr,
        indeterminateAll: unCheckedNum > 0,
        checkAll: unCheckedNum === 0,
        quantity: this.selected.length
      }
      this.options = data
    }, 500),
    onAccessControlChecked(e) {
      // 菜单选中返回结果
      this.selected = e
    },
    handleBase() {
      // 保存
      data.permission_id = this.selected
      this.$axios
        .baseRoleLevelMenu(data)
        .then(() => {
          this.$message.success('操作成功')
        })
        .catch((res) => {
          console.log(res)
        })
    }
  }
}
</script>
<style scoped>
.main-content-header {
  padding: 16px 32px;
  border-bottom: 1px solid #e9e9e9;
}

.body {
  background-color: #fff;
  padding: 24px 32px;
}

.button-box {
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 16px;
}

.button-box .item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.button-box .sub-item:first-child {
  display: flex;
  flex-grow: revert;
}

.button-box .sub-item:last-child {
  flex: 1;
}

.button-box .sub-item:last-child .checkbox {
  padding: 0 24px;
  display: flex;
  justify-content: flex-start;
}

.button-box .checkbox {
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-box .checkbox:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.button-box .checkbox-item {
  width: 146px;
}

.button-box .btn-item {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
</style>
