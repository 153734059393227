<template>
  <div class="body">
    <div>
      <a-checkbox
        :indeterminate="indeterminateAll"
        :checked="checkAll"
        @change="onCheckAllChange"
      >
        选择所有权限
      </a-checkbox>
    </div>

    <div class="button-box">
      <sub-checked
        v-for="(item, index) in menus"
        :key="item.id"
        :itemValue="item"
        :itemIndex="index"
        @change="onChange"
      ></sub-checked>
    </div>
  </div>
</template>
<script>
import { Debounce } from '@/utils/public'
import subChecked from './sub-checked'
let menuIdArr = [] // 选中的菜单id
export default {
  data() {
    return {}
  },
  props: {
    options: {
      type: Object,
      default: function() {
        return {}
      }
    },
    selected: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  computed: {
    menus() {
      menuIdArr = this.selected
      return this.options.data
    },
    checkAll: {
      get: function() {
        return this.options.checkAll
      },
      set: function(newValue) {
        this.options.checkAll = newValue
      }
    },
    indeterminateAll: {
      get: function() {
        return this.options.indeterminateAll
      },
      set: function(newValue) {
        this.options.indeterminateAll = newValue
      }
    }
  },
  components: {
    subChecked
  },
  methods: {
    onCheckAllChange(e) {
      // 选择所有
      const checked = e.target.checked
      this.checkAll = checked
      if (checked) {
        this.indeterminateAll = false
      }

      this.menus.forEach((event) => {
        event.checked = e.target.checked
        const data = {
          checked: e.target.checked,
          indeterminate: false,
          indexs: [],
          value: event
        }
        this.onChange(data)
      })
    },
    onUnChecked(e) {
      // 取消选中
      const that = this
      const indexs = e.indexs // 空数组：顶级；有数据，则根据长度判断有几个上级数据
      const indexsLength = indexs.length
      const array = [] // 当前check的数据以及直连上级数据数组
      let findNum = 0 // 查找次数，根据indexs长度，正序
      let changeIndex = indexsLength - 1 // 查找次数，倒序
      change()

      function change() {
        // 删除对应的菜单id
        that.removeMenuId(e.value.id)

        if (indexsLength) {
          // 有上级
          findUpItem(that.menus)
        }
        if (e.value.buttons.length) {
          // 取消所有按钮选中
          that.downButtonChange(e.value.id, e.value.buttons, false)
        }
      }

      function findUpItem(arr) {
        // 根据indexs数组中的索引值，获取menus菜单数组对应索引的值，
        // 下一轮自动根据indexs下一位索引继续获取
        // 最终找到indexs中最后一个索引的数据
        if (findNum === indexsLength) {
          upChange()
          findNum = 0
        } else {
          const i = indexs[findNum]
          array.push(arr[i])
          findNum++
          findUpItem(arr[i].children)
        }
      }

      function upChange() {
        // 更改上级菜单状态
        // console.log('更改上级状态', changeIndex)
        let checkedNum = 0
        array[changeIndex].children.forEach((event) => {
          // console.log(event)
          // 判断下级有没有选中
          if (event.checked || event.indeterminate) {
            checkedNum++
          }
        })
        if (checkedNum) {
          // 有选中
          if (checkedNum === array[changeIndex].children.length) {
            // 下级全选中
            array[changeIndex].checked = true
            array[changeIndex].indeterminate = false
          } else {
            // 下级一个或多个选中
            array[changeIndex].checked = false
            array[changeIndex].indeterminate = true
            that.downButtonChange(
              array[changeIndex].id,
              array[changeIndex].buttons,
              true
            )
          }
        } else {
          // 下级没有选中，判断该菜单的按钮是否有选中的
          let buttonNum = 0
          array[changeIndex].buttons.forEach((b) => {
            if (b.checked) {
              buttonNum++
            }
          })
          if (!buttonNum) {
            // 按钮没有选中的，则该菜单取消选中
            array[changeIndex].checked = false
            array[changeIndex].indeterminate = false
            // 只有该菜单连按钮也没有选中时，才删除该id
            that.removeMenuId(array[changeIndex].id)
          }
        }
        if (changeIndex > 0) {
          changeIndex--
          upChange()
        }
      }
    },
    onChecked(e) {
      // 选中
      const that = this
      const indexs = e.indexs // 空数组：顶级；有数据，则根据长度判断有几个上级数据
      const indexsLength = indexs.length
      const array = [] // 当前check的数据以及直连上级数据数组
      let findNum = 0 // 查找次数，根据indexs长度，正序
      let changeIndex = indexsLength - 1 // 查找次数，倒序
      change()

      function change() {
        // 添加对应的菜单id或按钮id
        const buttons = e.value.buttons
        if (buttons.length) {
          buttons.forEach((b) => {
            if (b.checked) {
              that.pushMenuId(b, true, e.value.id)
            } else {
              that.removeMenuId(e.value.id, true, b.value)
            }
          })
        } else {
          that.pushMenuId(e.value)
        }

        if (indexsLength) {
          // 有上级
          findUpItem(that.menus)
        }
      }

      function findUpItem(arr) {
        // 根据indexs数组中的索引值，获取menus菜单数组对应索引的值，
        // 下一轮自动根据indexs下一位索引继续获取
        // 最终找到indexs中最后一个索引的数据
        if (findNum === indexsLength) {
          upChange()
          findNum = 0
        } else {
          const i = indexs[findNum]
          array.push(arr[i])
          findNum++
          findUpItem(arr[i].children)
        }
      }

      function upChange() {
        // 更改上级菜单状态
        // console.log('更改上级状态', changeIndex)
        let checkedNum = 0
        array[changeIndex].children.forEach((event) => {
          // console.log(event)
          // 判断下级有没有选中
          if (event.checked || event.indeterminate) {
            checkedNum++
          }
        })
        if (checkedNum) {
          // 有选中
          if (checkedNum === array[changeIndex].children.length) {
            // 下级全选中
            array[changeIndex].checked = true
            array[changeIndex].indeterminate = false
          } else {
            // 下级一个或多个选中
            array[changeIndex].checked = false
            array[changeIndex].indeterminate = true
            that.downButtonChange(
              array[changeIndex].id,
              array[changeIndex].buttons,
              true
            )
          }
          that.pushMenuId(array[changeIndex])
        } else {
          // 下级没有选中，判断该菜单的按钮是否有选中的
          let buttonNum = 0
          array[changeIndex].buttons.forEach((b) => {
            if (b.checked) {
              buttonNum++
            }
          })
          if (!buttonNum) {
            // 按钮没有选中的，则该菜单取消选中
            array[changeIndex].checked = false
            array[changeIndex].indeterminate = false
            // 只有该菜单连按钮也没有选中时，才删除该id
            that.removeMenuId(array[changeIndex].id)
          }
        }
        if (changeIndex > 0) {
          changeIndex--
          upChange()
        }
      }
    },
    onDownChange(e) {
      // 更改下级菜单状态
      const that = this
      downChange(e)
      function downChange(itemValue) {
        const children = itemValue.value.children
        if (children) {
          children.forEach((event) => {
            if (itemValue.checked) {
              // 上级选中状态为true并且下级半选中状态也为true，
              // 则下级的选中状态设为true
              event.checked = true
            }
            if (!itemValue.checked && !itemValue.indeterminate) {
              // 上级选中状态为false并且下级半选中状态也为false，
              // 则下级的选中状态设为false
              event.checked = false
            }
            if (
              (itemValue.checked && event.indeterminate) ||
              (!itemValue.checked && event.indeterminate)
            ) {
              // 上级选中状态为true并且下级半选中状态为true，
              // 或者上级选中状态为false并且下级半选中状态为true，
              // 则下级的半选中状态设为false
              event.indeterminate = false
            }
            if (event.children) {
              // 更改下级菜单状态
              const data = {
                checked: event.checked,
                indeterminate: event.indeterminate,
                indexs: [],
                value: event
              }
              downChange(data)
            }
            if (event.buttons.length) {
              // 更改下级菜单按钮状态
              // console.log(itemValue)
              const data = {
                id: event.id,
                buttons: event.buttons,
                checked: event.checked,
                indeterminate: event.indeterminate
              }
              dowmChangeButtons(data)
            }
            if (event.checked || event.indeterminate) {
              // 添加对应的下级菜单id
              that.pushMenuId(event)
            }
            if (!event.checked && !event.indeterminate) {
              // 删除对应的下级菜单id
              that.removeMenuId(event.id)
            }
          })
        }
        // 当前菜单下的按钮
        if (itemValue.value.buttons.length) {
          const data = {
            id: itemValue.value.id,
            buttons: itemValue.value.buttons,
            checked: itemValue.checked,
            indeterminate: itemValue.indeterminate
          }
          dowmChangeButtons(data)
        }
        function dowmChangeButtons(value) {
          if ((!value.checked && !value.indeterminate) || value.checked) {
            // 更改下级菜单按钮状态
            that.downButtonChange(value.id, value.buttons, value.checked)
          }
        }
      }
    },
    downButtonChange(id, buttons, checked) {
      // 更改下级按钮状态
      buttons.forEach((event) => {
        event.checked = checked
        if (checked) {
          this.pushMenuId(event, true, id)
        } else {
          this.removeMenuId(id, true, event.value)
        }
      })
    },
    pushMenuId(value, isButton, id) {
      // 添加已选中的菜单id
      if (isButton) {
        // console.log(value, id)
        const item = menuIdArr.find((f) => f.id === id)
        if (item === undefined) {
          menuIdArr.push({
            id: id,
            buttons: [value]
          })
        } else {
          const buttonItem = item.buttons.find((f) => f.value === value.value)
          if (buttonItem === undefined) {
            item.buttons.push(value)
          }
        }
      } else {
        const index = menuIdArr.findIndex((f) => f.id === value.id)
        if (index === -1) {
          menuIdArr.push({
            id: value.id,
            buttons: []
          })
        }
      }
    },
    removeMenuId(id, isButton, buttonId) {
      // 删除已选中的菜单id
      // 找到对应已选中的菜单数据
      const idIndex = menuIdArr.findIndex((f) => f.id === id)
      if (isButton && idIndex !== -1) {
        // 按钮
        // 找到对应已选中的菜单按钮数据
        const buttonArr = menuIdArr[idIndex].buttons
        const bIndex = buttonArr.findIndex((f) => f.value === buttonId)
        if (bIndex !== -1) {
          // 有该菜单按钮id，则删除
          const buttons = buttonArr
          buttons.splice(bIndex, 1)
          const data = {
            id: id,
            buttons: buttons
          }
          menuIdArr.splice(idIndex, 1, data)
        }
      } else if (idIndex !== -1) {
        // 菜单
        menuIdArr.splice(idIndex, 1)
      }
    },
    onChange(e) {
      // 复选框变更事件
      if (e.checked || e.indeterminate) {
        this.onChecked(e)
      }
      if (!e.checked && !e.indeterminate) {
        this.onUnChecked(e)
      }
      this.onDownChange(e)
      this.onEmit()
      if (menuIdArr.length === this.options.quantity && this.indeterminateAll) {
        this.checkAll = true
        this.indeterminateAll = false
      } else {
        this.checkAll = false
        this.indeterminateAll = menuIdArr.length === 0 ? false : true
      }
    },
    onEmit: Debounce(function() {
      this.$emit('change', menuIdArr)
    }, 500)
  }
}
</script>
<style scoped>
.body {
  background-color: #fff;
  padding: 24px 32px;
}

.button-box {
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 16px;
}

.button-box .item {
  display: flex;
}

.button-box .item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.button-box .sub-item:first-child {
  display: flex;
  flex-grow: revert;
}

.button-box .sub-item:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.button-box .sub-item:last-child {
  flex: 1;
}

.button-box .sub-item:last-child .checkbox {
  padding: 0 24px;
  display: flex;
  justify-content: flex-start;
}

.button-box .checkbox {
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-box .checkbox:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.button-box .checkbox-item {
  width: 146px;
}

.button-box .btn-item {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
</style>
