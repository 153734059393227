<template>
  <div class="item">
    <div class="sub-item" :data-index="itemIndex" :data-id="itemValue.id">
      <a-popover
        placement="bottomLeft"
        v-if="itemValue.buttons.length"
        @mouseenter.native="popoverMouse"
      >
        <template slot="content">
          <a-checkbox
            class="access-control-checked-popover-up-checkbox"
            :indeterminate="itemValue.indeterminate"
            :checked="itemValue.checked"
            @change="onChange($event, null)"
          >
            {{ itemValue.name }}（其他/详情页）
          </a-checkbox>
          <div class="checkbox">
            <a-checkbox
              v-for="(item, index) in itemValue.buttons"
              :key="item.value"
              :checked="item.checked"
              @change="onChange($event, index)"
            >
              {{ item.label }}
            </a-checkbox>
          </div>
        </template>
        <template slot="title">
          <a-breadcrumb separator=">">
            <a-breadcrumb-item v-for="(item, index) in parent_ids" :key="item"
              >{{ number[index] }}级菜单</a-breadcrumb-item
            >
            <a-breadcrumb-item>尾部菜单</a-breadcrumb-item>
          </a-breadcrumb>
        </template>
        <a-button type="link" ghost>
          <a-checkbox
            class="access-control-checked-popover-checkbox"
            :class="{
              'access-control-checked-popover-checkbox-button':
                itemValue.buttons.length > 0
            }"
            :indeterminate="itemValue.indeterminate"
            :checked="itemValue.checked"
            @change="onChange"
          >
            {{ itemValue.name }}
          </a-checkbox>
        </a-button>
      </a-popover>

      <a-checkbox
        v-else
        :indeterminate="itemValue.indeterminate"
        :checked="itemValue.checked"
        @change="onChange"
      >
        {{ itemValue.name }}
      </a-checkbox>
    </div>

    <template v-if="itemValue.children">
      <div class="sub-item" :data-index="itemIndex" :data-id="itemValue.id">
        <sub-checked
          v-for="(item, index) in itemValue.children"
          :key="item.id"
          :itemValue="item"
          :itemIndex="index"
          @change="onChange"
        ></sub-checked>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'sub-checked',
  data() {
    return {
      parent_ids: [],
      number: ['一', '二', '三', '四']
    }
  },
  props: {
    itemValue: {
      type: Object,
      default: function() {
        return {}
      }
    },
    itemIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onChange(e, i) {
      // 复选框变更事件
      // console.log(e)
      if (e.target) {
        if (i === undefined) {
          menuChage(this)
        } else {
          buttonChange(this)
        }
      } else {
        this.$emit('change', e)
      }

      function menuChage(that) {
        that.itemValue.checked = e.target.checked
        if (e.target.checked) {
          that.itemValue.indeterminate = false
        }
        const indexs = []
        let num = 0
        e.nativeEvent.path.forEach((item) => {
          // 获取上级索引
          if (item.className === 'sub-item') {
            if (num === 0) {
              num++
            } else if (num === 1) {
              indexs.push(Number(item.previousSibling.dataset.index))
            }
          }
        })
        indexs.reverse() // 颠倒排序，越上级越在前
        // console.log(that.itemValue)
        that.$emit('change', {
          checked: e.target.checked,
          indeterminate: that.itemValue.indeterminate,
          indexs: indexs,
          value: that.itemValue
        })
      }

      function buttonChange(that) {
        const buttons = that.itemValue.buttons
        if (i === null) {
          // 按钮弹窗内的菜单
          that.itemValue.checked = e.target.checked
        } else {
          // 按钮菜单内的按钮
          buttons[i].checked = e.target.checked
          let checkedNum = 0
          buttons.forEach((b) => {
            if (b.checked) {
              checkedNum++
            }
          })
          if (checkedNum === buttons.length) {
            if (!that.itemValue.checked) {
              that.itemValue.checked = true
            }
            if (that.itemValue.indeterminate) {
              that.itemValue.indeterminate = false
            }
          } else {
            if (that.itemValue.checked) {
              that.itemValue.checked = false
            }
            if (!that.itemValue.indeterminate) {
              that.itemValue.indeterminate = true
            }
          }
          if (!checkedNum) {
            if (that.itemValue.checked) {
              that.itemValue.checked = false
            }
            if (that.itemValue.indeterminate) {
              that.itemValue.indeterminate = false
            }
          }
        }

        // console.log(that.itemValue)

        setTimeout(() => {
          // 延迟后执行，不延迟会出现先后顺序问题
          const dom = document.querySelectorAll('.sub-item')
          const domArr = Array.prototype.slice.call(dom)
          const domIndex = domArr.findIndex((f, index) => {
            const domId = f.getAttribute('data-id')
            if (Number(domId) === that.itemValue.id) {
              return index
            }
          })
          const inputDom = dom[domIndex].querySelector('.ant-checkbox-input')
          FireEvent(inputDom, 'change')
        }, 100)

        function FireEvent(elem, eventName) {
          // 手动调用dom的function
          if (typeof elem == 'object') {
            eventName = eventName.replace(/^on/i, '')
            if (document.all) {
              eventName = 'on' + eventName
              elem.fireEvent(eventName)
            } else {
              var evt = document.createEvent('HTMLEvents')
              evt.initEvent(eventName, true, true)
              elem.dispatchEvent(evt)
            }
          }
        }
      }
    },
    popoverMouse() {
      // 鼠标移动到复选框上时
      this.parent_ids = this.itemValue.parent_ids
    }
  }
}
</script>
<style scoped>
.item {
  flex: 1;
  min-height: 54px;
  display: flex;
  align-items: center;
}

.item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.sub-item .ant-checkbox-wrapper,
.access-control-checked-popover-checkbox {
  padding: 0 24px;
}

.sub-item {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-item {
  flex: 0 0 200px;
}

.button-box > .item > .sub-item:first-child {
  /* 选择父元素第一层元素的第一个元素 */
  flex: 0 0 170px;
}

.item:only-child,
.sub-item:only-child,
.item:last-child,
.sub-item:last-child {
  flex: 1;
}

.sub-item:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-btn {
  padding: 0;
  text-align: left;
}
</style>
<style>
.access-control-checked-popover-up-checkbox {
  margin-bottom: 16px;
}

.access-control-checked-popover-up-checkbox span:nth-child(2) {
  color: #000;
  font-weight: bold;
}

.access-control-checked-popover-checkbox-button span {
  color: #1890ff;
  text-decoration: underline;
}
</style>
